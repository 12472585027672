/*CUSTOMIZE MATERIAL CALENDAR*/
.MuiPickersBasePicker-container > div:first-child {
  display: flex;
  align-items: stretch;
}

.MuiPickersBasePicker-container h2.MuiPickersToolbarText-toolbarTxt {
  font-size: 21px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
h2.MuiPickersToolbarText-toolbarTxt::after,
h2.MuiPickersToolbarText-toolbarTxt::before {
  content: ' -- ';
  display: inline-block;
  margin: 0 px;
}
/* h2.MuiPickersToolbarText-toolbarTxt::after {
  content: 'Chọn năm';
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
} */
.MuiPickersBasePicker-container h4.MuiPickersToolbarText-toolbarTxt {
  display: none;
}
.MuiPickersSlideTransition-transitionContainer span {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
}
.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  height: auto;
  padding: 0 20px;
  background: linear-gradient(180deg, #d91a21 34.8%, #6f0000 182.34%);
}
.MuiPickersBasePicker-container .MuiPickersYear-root {
  font-size: 18px;
}
.MuiPickersBasePicker-container .MuiPickersYear-yearSelected {
  font-size: 24px;
}
.MuiPickersBasePicker-container .MuiPickersCalendarHeader-dayLabel {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
.MuiPickersBasePicker-container .MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.3);
}
.MuiPickersBasePicker-container .MuiPickersMonth-root {
  font-size: 16px;
  text-transform: capitalize;
}
.MuiPickersBasePicker-container .MuiPickersMonth-monthSelected {
  font-size: 20px;
  font-weight: 600;
}
.MuiPaper-root.MuiMenu-paper {
  transform: translateY(53px) !important;
  transform-origin: 0 !important;
}

/* width */

.scrollCustom::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px !important;
}

/* Track */
.scrollCustom::-webkit-scrollbar-track {
  background: #a0a0a0;
  border-radius: 10px !important;
}

/* Handle */
.scrollCustom::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #f9a51a 0%, #fedc00 100%);
  border-radius: 10px !important;
}


.fade-text::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -40px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: #EC2029;
}

.fade-text::after {
  content: "";
  position: absolute;
  top: -20px;
  right: -40px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: #EC2029;
}

@media only screen and (max-width: 768px) {
  .fade-text::after {
    top: -10px;
    right: -20px;
    height: 20px;
    width: 20px;
  }

  .fade-text::before {
    top: -10px;
    left: -20px;
    height: 20px;
    width: 20px;
  }

}
