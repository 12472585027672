html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: KoHo, sans-serif !important;
  background-color: white !important;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 500 !important;
}

* {
  box-sizing: border-box;
  outline: 0;
  scroll-behavior: smooth;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

input:-internal-autofill-selected {
  background: transparent !important;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

@font-face {
  font-family: 'JambonoMedium';
  src: url(/fonts/Jambono-Medium.ttf);
}

@font-face {
  font-family: 'JambonoBlack';
  src: url(/fonts/Jambono-Black.ttf);
}

@font-face {
  font-family: 'JambonoLight';
  src: url(/fonts/Jambono-Light.ttf);
}

@font-face {
  font-family: 'OpenSans';
  src: url(/fonts/OpenSans.ttf);
}

@font-face {
  font-family: 'OpenSansItalic';
  src: url(/fonts/OpenSans-Italic.ttf);
}

@font-face {
  font-family: 'SansSerifBold';
  src: url(/fonts/SansSerif-Bold.ttf);
}

@font-face {
  font-family: 'SansSerifItalic';
  src: url(/fonts/SansSerif-Italic.ttf);
}

@font-face {
  font-family: 'SansSerif';
  src: url(/fonts/SansSerif.ttf);
}

@import 'assets/style/customize.css';

.osc-button {
  bottom: 60px !important;
}
